.notifications {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  max-width: 300px;
  height: 100vh;
  padding: 20px;
  pointer-events: none;
  z-index: 1000;
}
