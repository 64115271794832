.live-chat-support-container {
  position: fixed;
  right: 10px;
  bottom: 20px;
  z-index: 100;
  background-color: transparent !important;

  &.open {
    width: calc(100% - 20px);
    max-width: 350px;
  }

  .messages-container {
    height: auto;
    max-height: 40vh;
    overflow-y: auto;
  }

  .fa-angle-down {
    cursor: pointer;
  }

  .message {
    width: 95%;
    padding-left: 10px;
    border: 1px solid #999999;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;

    &.operator {
      background-color: #aaaaaa;
      color: #000000;
    }

    &.customer {
      margin-left: 5%;
      background-color: #ffffff;
    }
  }

  input {
    font-size: 14px !important;
  }

  .message-input {
    resize: none;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 14px;
  }

  .open-chat-btn {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    background-color: #329586;
    color: #ffffff;
    box-shadow: 0 0 3px #000000;
    transition: background-color 200ms ease;

    &:hover {
      background-color: #0a0a0a;
    }

    #support_user_svg {
      width: 64%;

      path {
        fill: #ffffff;
      }
    }

    .comment-bubble {
      position: absolute;
      width: 94px;
      transform: scale(0);
      right: -20px;
      bottom: -20px;
      animation: bubble-animation 4s 400ms infinite ease;

      span {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        font-size: 13px;
        font-weight: bold;
        line-height: 1.1;
      }

      #comment_svg {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;

        path {
          fill: #666666
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .live-chat-support-container {
    right: calc(((100vw - 1320px) / 2) + 10px);
  }
}

@keyframes bubble-animation {
  0% {
    transform: scale(0);
    right: -20px;
    bottom: -20px;
  }

  25% {
    transform: scale(1);
    right: 26px;
    bottom: 22px;
  }

  100% {
    transform: scale(1);
    right: 26px;
    bottom: 22px;
  }
}
