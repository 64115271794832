.notification-item {
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 0 5px #1f1f1f;

  &.info {
    border-bottom: 6px solid #0dcaf0;
  }

  &.success {
    border-bottom: 6px solid #198754;
  }

  &.error {
    border-bottom: 6px solid #dc3545;
  }

  &.warning {
    border-bottom: 6px solid #ffc107;
  }

  .notification-text {
    flex-grow: 1;
    padding-right: 5px;
    color: #1f1f1f;
  }

  .notification-close {
    flex-shrink: 0;
    pointer-events: visible;
    width: 16px;
    height: 16px;
    padding: 3px;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
    }
  }
}
