.secret-copy-text {
  .fas {
    cursor: pointer;
  }

  .input-btn-box {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}