.tooltip-wrapper {
  position: absolute;
  top: calc(50% - 13px);
  right: -28px;
  cursor: help;
}
.tooltip-icon {
  pointer-events: none;
}
.tooltip-mt-account-number {
  .tooltip-inner {
    text-align: left;
    width: 350px;
    max-width: 80vw;
    a {
      color: yellowgreen;
    }
  }
}

.tooltip-binary-token .tooltip-inner {
  text-align: left;
  width: 371px;
  max-width: 80vw;
  a {
    color: yellowgreen;
  }
}

.cart {
  input:disabled+label {
    color: #ccc;
    .link-button {
      color: #ccc;
      cursor: default;
    }
  }
}
