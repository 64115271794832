.subscription-settings {
  top: 0;
  left: 0;
  z-index: 10;

  .subscription-settings-bg {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .subscription-settings-info {
    width: calc(100% - 40px);
    max-width: 400px;
  }
}
