.App {
  width: 100%;
  height: 100%;
}
a {
  color: #44cbb5;
  text-decoration: none;
}

a:hover {
  color: #3BEDE9;
  text-decoration: none;
}
.text-success-2 {
  color: #44cbb5;
}
.btn-binarydew {
  background: #329586;
  color: white !important;
}
.text-binarydew {
  color: #329586;
}
