@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

html, body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #727272;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

.navbar-nav .nav-item a.active {
  font-weight: bold;
  text-shadow: 0 0 5px #ffffff;
}

.breadcrumbs {
  padding-top: 140px;
  padding-bottom: 30px;
}

.breadcrumbs .breadcrumb-nav li a.active {
  font-weight: 700;
  text-shadow: 0 0 3px #ffffff;
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .breadcrumbs {
    padding-top: 110px;
    padding-bottom: 20px;
  }
}

@media(max-width:767px) {
  .breadcrumbs {
    padding-top: 100px;
    padding-bottom: 20px;
  }
}

.breadcrumbs .breadcrumb-nav li:after {
  display: none;
}

.btn-social {
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}