.profit-counter {
  width: 100%;
  height: 100%;

  .profit {
    position: fixed;
    color: #ffffff;
    font-size: 18px;
    top: 38px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 100;

    > div {
      padding-left: 200px;
    }
  }
}

.App {
  &.scrolled .profit-counter .profit {
    color: #0a0a0a;
  }
}

@media (min-width: 992px) {
  .profit-counter {
    .profit {
      top: 28px;

      > div {
        padding-left: 210px;
      }
    }
  }
}
