.pricing-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  flex: 0 1 330px;
  cursor: default;
  color: #84697c;
  background: #fff;
  box-shadow: 0 0 10px #0a0a0a;
  border-radius: 20px 20px 10px 10px;
  overflow: hidden;

  .color-bar {
    height: 5%;
    width: 100%;
    position: absolute;
    z-index: 1;

    &.bronze {
      background: #CD7F32;
      box-shadow: 0 0 10px 5px #CD7F32;
    }

    &.silver {
      background: #C0C0C0;
      box-shadow: 0 0 10px 5px #C0C0C0;
    }

    &.gold {
      background: #FFD700;
      box-shadow: 0 0 10px 5px #FFD700;
    }

    &.expired {
      float: top;
      color: white;
      background: red;
      box-shadow: 0 0 10px 5px red;
    }
  }

  &.featured {
    transform: scale(1.07, 1.1);
    @media screen and (max-width: 575px) {
      transform: scale(1.03, 1.05);
    }
  }

  &:hover {
    .deco-layer--1 {
      transform: translateX(15px);
    }

    .deco-layer--2 {
      transform: translateX(-15px);
    }
  }

  .pricing-ribbon {
    position: absolute;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 90px;
    top: 25px;
    left: 25px;
    background-color: #999999;
    font-size: 12px;
    color: #ffffff;
    z-index: 1;
  }

  .pricing-deco {
    border-radius: 10px 10px 0 0;
    background: #0a0a0a;
    padding: 3em 0 6em 0;
    position: relative;

    .pricing-price {
      font-size: 5em;
      font-weight: bold;
      padding: 0;
      color: #fff;
      margin: 0 0 0.25em 0;
      line-height: 0.75;

      .pricing-currency {
        font-size: 0.5em;
        vertical-align: top;
      }

      .pricing-period {
        font-size: 0.15em;
        padding: 0 0 0 0.5em;
        font-style: italic;
      }
    }

    .pricing-deco-img {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
    }

    .pricing-title {
      font-size: 0.75em;
      margin: 0;
      text-transform: uppercase;
      letter-spacing: 5px;
      color: #fff;
    }

    .deco-layer {
      -webkit-transition: -webkit-transform 0.5s;
      transition: transform 0.5s;
    }
  }

  .pricing-feature-list {
    margin: -20px 0 0 0;
    padding: 0 0 1rem 0;
    list-style: none;
    text-align: center;

    .pricing-feature {
      padding: 1em 0;
    }
  }

  .pricing-action {
    border: none;
    background: none;
    font-weight: bold;
    margin: auto 3em 2em 3em;
    padding: 1em 2em;
    color: #fff;
    border-radius: 30px;
    background: #4d4766;
    transition: background-color 0.3s;

    &:hover {
      background-color: #100A13;
    }

    &:focus {
      outline: none;
      background-color: #100A13;
    }
  }
}
