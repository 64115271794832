.sidebar {
  border-right: 1px solid #000000;

  ul {
    list-style: none;

    .nav-link {
      color: #444444;

      &:hover {
        color: #666666;
      }

      &:active {
        color: #222222;
      }

      &.active {
        color: #000000;
      }
    }
  }
}