.about-us-stats-container {
  position: absolute;
  top: 0;
  width: 100%;
  max-height: 100px;
}

.about-us-stats-box {
  min-width: 195px;
  width: fit-content;
  margin-top: 150px;
  border-radius: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 30px rgba(81,94,125,.082);
  box-shadow: 0 0 30px rgba(81,94,125,.082);
  padding: 10px 20px;
  text-align: center;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-bottom: 3px solid #0a0a0a;

  @media only screen and (min-width:768px) and (max-width:991px) {
    margin-top: 130px;
  }

  @media only screen and (min-width: 1400px) {
    margin-top: 130px;
  }

  @media (max-width: 544px) {
    padding: 5px 10px;
  }

  .text-title {
    color: #329586;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;

    /* Small devices (landscape phones, 544px and up) */
    @media (max-width: 544px) {
      font-size:1rem; /*1rem = 16px*/
    }
  }

  i {
    color: #0a0a0a;
  }

  &.stats-box-success:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-top-color: #00b74a;
    border-bottom-color: #00b74a;
  }
  &.stats-box-primary:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-top-color: #1266f1;
    border-bottom-color: #1266f1;
  }
  &.stats-box-danger:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-top-color: #f93154;
    border-bottom-color: #f93154;
  }
}
.section-about-us .section-title {
  padding: 0 10%;
}

