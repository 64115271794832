.link-box.feature-box {
  position: relative;

  a:first-child {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    font-size: 0;
  }
}

.tutorials {
  .screenshot {
    width: 100%;
    align-content: center;
    text-align: center;

    img {
      width: 70%;
    }
  }
  .screenshot-deriv {
    width: 100%;
    align-content: center;
    text-align: center;
  }
}

.link-button {
  background-color: transparent;
  color: #44cbb5;
  border: none;
  cursor: pointer;
}
