.contact-us .breadcrumbs {
  background-image: url('assets/contact-us-header.jpg');
  background-position: top;
  padding-bottom: 80px;
  z-index: 1;
}

.contact-us-content {
  /* Force overlap with breadcrumbs */
  margin-top: -20px;
}

.contact-us-box-container {
  /* Show on top of breadcrumbs' header image */
  position: relative;
  z-index: 1;
}

.contact-us-box {
  position: relative;
  width: 100%;
  min-width: 195px;
  height: 100%;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(81,94,125,.082);
  text-align: center;
  border-bottom: 3px solid #0a0a0a;
  border-radius: 20px;
  cursor: pointer;
  transition: all .4s ease;

  &.link-box {
    position: relative;

    a:first-child {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      font-size: 0;
    }
  }

  &.disabled {
    cursor: default;
    border-bottom: 3px solid #329586;

    &:after {
      position: absolute;
      content: '';
      width: calc(100% - 28px);
      height: 30px;
      top: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='25' width='356'%3E%3Cpolygon points='0,0 356,0 178,25' style='fill: rgb(50,149,134);stroke;' /%3E Sorry, your browser does not support inline SVG.%0A%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: center;
    }
  }

  &:not(.disabled):hover {
    border-top-color: #329586;
    border-bottom-color: #329586;
    transform: scale(1.05);
  }

  img {
    height: 250px;
    width: auto;
    padding: 20px;
  }

  .text-title {
    color: #329586;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;

    /* Small devices (landscape phones, 544px and up) */
    @media (max-width: 544px) {
      font-size:1rem; /*1rem = 16px*/
    }
  }
}

.contact-us .email-box {
  position: relative;
  margin-top: calc(7% + 24px);
  margin-bottom: 30px;
  background-color: #329586;
  width: 100%;
  border-radius: 20px;

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 32px);
    height: 0;
    padding-top: 7%;
    bottom: 100%;
    left: 50%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='25' width='356'%3E%3Cpolygon points='0,0 356,0 178,25' style='fill: rgb(50,149,134);' /%3E Sorry, your browser does not support inline SVG.%0A%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    transform: translateX(-50%) rotate(180deg);
  }
}

@media only screen and (min-width:768px) and (max-width:991px) {
  .contact-us .breadcrumbs {
    padding-bottom: 80px;
  }
}

@media(max-width:767px) {
  .contact-us .breadcrumbs {
    background-position: right;
    padding-bottom: 80px;
  }

}

@media only screen and (max-width:1400px) {
  .contact-us .email-box {
    margin-top: calc(7% + 20px);
  }
}

@media only screen and (min-width:992px) and (max-width: 1199px) {
  .contact-us .email-box {
    margin-top: calc(7% + 16px);
  }
}

@media only screen and (max-width: 991px) {
  .contact-us .email-box {
    margin-top: calc(7% + 10px);
  }
}

@media(min-width:1400px) {
  .contact-us .breadcrumbs {
    background-position: center;
  }
}
