header {
  .navbar {
    box-shadow: none !important;
    padding: 0 !important;

    .nav-item {
      button {
        border: none;
        background: none;
        outline: none;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        width: 100%;
        color: #051441;
      }

      &:focus {
        border: none;
        background: none;
        outline: none;
      }
    }

    .header-user {
      height: 94px;
      display: flex;
      align-items: center;

      .header-user-info {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          object-position: center center;
          border-radius: 50%;
        }

        span, i {
          color: #ffffff;
        }

        .fa-angle-up {
          display: none;
        }

        &:hover {
          span, i {
            color: #329586;
          }

          .fa-angle-down {
            display: none;
          }

          .fa-angle-up {
            display: block;
          }
        }
      }

      .header-user-sub-menu {
        position: absolute;
        width: 100%;
        margin: 0;
        padding: 15px 20px;
        top: 100%;
        background-color: #ffffff;
        box-shadow: 0 5px 20px #0000001a;
        border-radius: 5px;
        transform: translateY(10px);
        opacity: 0;
        visibility: hidden;
        transition: all 500ms ease;

        li {
          a, button {
            color: #888;
            border: none;
            background: none;
            outline: none;
            margin: 0;
            padding: 5px 0;
          }

          &:focus {
            background: none;
            outline: none;
          }
        }
      }

      &:hover, &.open {
        .header-user-sub-menu {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }

      &.open {
        .header-user-info {
          span, i {
            color: #329586;
          }

          .fa-angle-down {
            display: none;
          }

          .fa-angle-up {
            display: block;
          }
        }
      }
    }
  }

  &.sticky {
    .navbar {
      .header-user {
        .header-user-info {
          span, i {
            color: #0a0a0a;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  header {
    .navbar {
      .header-user {
        display: none;
      }
    }
  }
}
