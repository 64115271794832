.home-pricing {
  background-color: #f4f7fa;

  /*.home-pricing-slides {
    position: relative;

    .home-pricing-slide-hidden {
      opacity: 0;
      pointer-events: none;
    }

    .home-pricing-slide {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(0);
      opacity: 1;
      transition: all 1.5s linear;

      &.hide-left {
        transform: translateX(-100%);
        opacity: 0;
        transition: none;
      }

      &.hide-right {
        transform: translateX(100%);
        opacity: 0;
      }
    }
  }*/
}

.hero-area .hero-shape {
  position: absolute;
  width: calc(110% + 1.3px);
  height: 67px;
  left: -5%;
  bottom: 0;
  z-index: -1;
  transform: rotate(180deg);

  path {
    fill: #ffffff;
    transform-origin: center;
    transform: rotateY(0deg);
  }
}

.testimonial-slider {
  &.testimonial-slider-2 {
    direction: rtl;
  }

  .testimonial-slides {
    width: max-content;
    transform: translateX(0);

    &.testimonial-slides-1 {
      animation: scroll-1 80s linear infinite;
    }

    &.testimonial-slides-2 {
      animation: scroll-2 80s linear infinite;
    }

    &:hover {
      animation-play-state: paused;
    }

    .testimonial-slide {
      direction: ltr;
      max-width: 450px;
    }
  }
}

@keyframes scroll-1 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(50%);
  }
}

.tradings-section {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 636px;

  .tradings-table {
    .clock {
      font-family: Orbitron, sans-serif;
      letter-spacing: 5px;
      font-size: 1.5rem;
      color: #727272;
      text-shadow: 0 0 1px #727272;
    }
    .tradings-table-header {
      border-bottom: 1px solid #727272;

      div {
        width: calc(100% / 7);

        &:last-child {
          width: calc(100% / 7 * 2)
        }
      }
    }

    .tradings-table-body {
      .tradings-table-row {
        &:nth-child(even) {
          background-color: #0000000a;
        }

        &.new-row {
          animation: animation-table-row 2s ease;
          overflow: hidden;

          i {
            animation: animation-table-row-icon 2s ease;
          }
        }

        &.not-sold {
          background-color: #00ff000f;
        }

        div {
          width: calc(100% / 7);

          &:last-child {
            width: calc(100% / 7 * 2)
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .tradings-table {
    .tradings-table-header {
      div {
        font-size: 12px;
      }
    }

    .tradings-table-body {
      .tradings-table-row {
        div {
          font-size: 12px;
        }
      }
    }
  }
}

@keyframes animation-table-row {
  0% {
    max-height: 0;
    color: transparent;
  }

  50% {
    max-height: 100px;
    color: transparent;
  }

  100% {
    max-height: 100px;
    color: #727272;
  }
}

@keyframes animation-table-row-icon {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.iframe-container {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.accordion-button:not(.collapsed) {
  color: #fff !important;
  background-color: #329586 !important;
}

.accordion-button i {
  top: calc(50% - 7.5px) !important;
  transform: rotate(0deg) !important;
  transition: transform 500ms ease !important;
}

.accordion-button:not(.collapsed) i {
  transform: rotate(180deg) !important;

  &:before {
    content: "\f13a";
    font-family: "Font Awesome 6 Free";
  }
}

.accordion .collapse {
  display: block;
  max-height: 300px;
  overflow: hidden;
  transition: max-height 500ms ease;

  &:not(.show) {
    display: block;
    max-height: 0;
  }
}

.contracts-stats-box {
  //min-width: 195px;
  width: fit-content;
  border-radius: 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 30px rgba(81,94,125,.082);
  box-shadow: 0 0 30px rgba(81,94,125,.082);
  padding: 10px 20px;
  text-align: center;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
  border-bottom: 3px solid #0a0a0a;

  @media (max-width: 544px) {
    padding: 5px 10px;
  }

  .text-title {
    color: #329586;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px;

    /* Small devices (landscape phones, 544px and up) */
    @media (max-width: 544px) {
      font-size:1rem; /*1rem = 16px*/
    }
  }

  i {
    color: #0a0a0a;
  }

  &.stats-box-success:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-top-color: #00b74a;
    border-bottom-color: #00b74a;
  }
  &.stats-box-primary:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-top-color: #1266f1;
    border-bottom-color: #1266f1;
  }
  &.stats-box-danger:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    border-top-color: #f93154;
    border-bottom-color: #f93154;
  }
}
